import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./styles/reset.scss";
import "./styles/common.scss";
import "./styles/app.scss";
import './pages/about/style.scss';
import "./pages/home/style.scss";
import "./pages/changelog/style.scss";
import "./pages/contact/style.scss";

import ChangeLogPage from './pages/changelog'
import FullWidthProduct from "./widgets/fullWidthProduct";
import Footer from "./widgets/footer";

class AppView extends Component {
  constructor(params) {
    super(params);
    this.state = {
      locality: null,
      user: null,
      section: "view",
      retries: 0,
    };
  }
  handleUserChange = (user) => {
    this.setState({
      user: user,
    });
  };
  render() {
    const auroraText =
      "AuroraReach gives predictions and alerts users where/how/when to see Auroras easily! Global aurora Tracking System built with modern technologies. Built with community in mind and the basics available to anyone to find and see an aurora around the world.";
    const auroraAppText =
      "AuroraReach App makes it easier to get aurora app notification with a click of a button. App has similar features as web app including searching over cities and exploring realtime map of aurora activities. In addition, one can add cities to favorites without logging in and also get global stats to start hunting auroras and more.";
    const gatherMuchText = 'A brave and innovative new ways to manage setting up events/impromptu gatherings and helping you make memories! Launched this summer 2024 to the public and ready to have your event related offering listed!';
    const appMeta = {
      playstore: {
        image: "https://cdn.aurorareach.com/appstore/playstore.svg",
        url: "https://play.google.com/store/apps/details?id=com.aurorareach.app",
      },
      appstore: {
        image: "https://cdn.aurorareach.com/appstore/ios.svg",
        url: "https://apps.apple.com/app/id1576251657",
      },
    };
    return (
      <div>
        <div className="home-hero">
          <div className="container">
            <div className="home-hero-columns">
              <div className="home-hero-preview">
                <img
                  src="https://cdn.huskycodes.com/husky.social.bits.png"
                  alt="HuskyCodes Logo"
                />
              </div>
              <div className="home-hero-meta">
                {/* <h1 className="home-hero-meta-heading">
                  HuskyCodes
                </h1> */}
                <img className='logotext' alt="HuskyCodes Text Logo" src="https://cdn.huskycodes.com/text.full.png" />
                <h1 className="home-hero-meta-text">
                  "2 developers building products"
                </h1>
                <p className="home-hero-meta-slogan">
                  Based in the Nordics, we are building niche products that we
                  are passionate about. We enjoy building Apps & websites that
                  solve the problems we have experienced ourselves in the past &
                  turn some of our hobby projects into real world useful
                  products. Check out our products below.
                </p>
                {/* <div className="home-hero-meta-actions">
                        <Link to="/contact" className="products-button">Contact Us</Link>
                      </div> */}
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="products-intro-container">
          <p className="products-title">Our Products</p>
          <div className="products-intro-desc">

            <p className="products-intro-meta">Check out our ongoing and published creative products. </p>
          </div>
          {/* <div className="products-links">
            <Link to="/contact" className="products-button">See All Products</Link>
          </div> */}
          <div className="clearfix"></div>
        </div>
        
        <br />
        <br />
        <br />

        <FullWidthProduct
          productSlug="aurorareach"
          title="AuroraReach"
          type="Web App"
          text={auroraText}
          imageURL="https://cdn.aurorareach.com/WebsiteDemo_AuroraReach.svg"
          productURL="https://aurorareach.com?source=huskycodes"
        ></FullWidthProduct>
        <FullWidthProduct
          productSlug="aurorareachApp"
          title="AuroraReach App"
          type="Mobile App"
          text={auroraAppText}
          imageURL="https://cdn.aurorareach.com/MobileDemo_AuroraReach.svg"
          appMeta={appMeta}
        ></FullWidthProduct>
        <FullWidthProduct
          productSlug="gathermuch"
          title="GatherMuch"
          maxHeight="500px"
          marginBottom="-10px"
          type="Web App"
          text={gatherMuchText}
          productURL="https://gathermuch.com"
          imageURL="https://cdn.gathermuch.com/assets/text.color.png"
        ></FullWidthProduct>

        <div className="container">
          <div className="contact-hero-meta">
            <h1 className="contact-hero-heading">Contact Us</h1>
            <p className="contact-hero-slogan">
              We aim to be easy to get in touch with and will get back as soon
              as possible!
            </p>
            <br />
          </div>
        </div>

        <div className="contact-dialog">
          <div className="contact-dialog-preview">
            <img src="https://cdn.huskycodes.com/assets/map.png" />
          </div>
          <div className="contact-dialog-meta">
            <h2 className="contact-dialog-heading contact-dialog-heading-first">
              <span className="fas fa-map-marked-alt"></span> Business Details
            </h2>
            <p className="contact-dialog-text">HuskyCodes Oy (3195113-2)</p>
            <p className="contact-dialog-text">Founded 2021</p>
            <h2 className="contact-dialog-heading">
              <span className="fas fa-map-marked-alt"></span> Address
            </h2>
            <p className="contact-dialog-text">Helsinki, Finland</p>
            <h2 className="contact-dialog-heading">
              <span className="fas fa-envelope-open"></span> E-Mail
            </h2>
            <p className="contact-dialog-text">
              <a href="mailto:info@huskycodes.com">info@huskycodes.com</a>
            </p>
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="about-people" style={{marginBottom: '30px'}}>
        <div className="products-intro-container">
          <p className="products-title">The Team</p>
          {/* <div className="products-links">
            <Link to="/contact" className="products-button">See All Products</Link>
          </div> */}
          <div className="clearfix"></div>
        </div>
        <div className="container">
          <div className="about-person">
            <div className="about-person-inner">
              <div className="about-person-image">
                <img src="https://cdn.huskycodes.com/team/dipikabaad.jpeg" />
              </div>
              <div className="about-person-meta">
                <h3 className="about-person-name">Dipika Baad</h3>
                <p className="about-person-title">co-Founder / Managing Director</p>
                <p className="about-person-contact">
                  <a href="mailto:dipika@huskycodes.com">dipika@huskycodes.com</a>
                </p>
                <div className="about-person-actions">
                <a href="https://dipikabaad.medium.com/" className="about-person-action">
                    <span className="fab fa-medium"></span>
                  </a>
                  <a href="https://github.com/dipikabaad" className="about-person-action">
                    <span className="fab fa-github-square"></span>
                  </a>
                  <a href="https://twitter.com/dipikabaad" className="about-person-action">
                    <span className="fab fa-twitter-square"></span>
                  </a>
                  <a href="https://www.linkedin.com/in/dipika-baad-154a2858/" className="about-person-action">
                    <span className="fab fa-linkedin"></span>
                  </a>
                  <a href="mailto:dipika@huskycodes.com" className="about-person-action">
                    <span className="fas fa-envelope"></span>
                  </a>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>


          <div className="about-person">
            <div className="about-person-inner">
              <div className="about-person-image">
                <img src="https://cdn.huskycodes.com/team/johanndutoit.jpeg" />
              </div>
              <div className="about-person-meta">
                <h3 className="about-person-name">Johann du Toit</h3>
                <p className="about-person-title">co-Founder / Chairman of Board</p>
                <p className="about-person-contact">
                  <a href="mailto:dipika@huskycodes.com">johann@huskycodes.com</a>
                </p>
                <div className="about-person-actions">
                  <a href="https://johanndutoit.net" className="about-person-action">
                    <span className="fas fa-link"></span>
                  </a>
                  <a href="https://github.com/johanndutoit" className="about-person-action">
                    <span className="fab fa-github-square"></span>
                  </a>
                  <a href="https://twitter.com/signedness" className="about-person-action">
                    <span className="fab fa-twitter-square"></span>
                  </a>
                  <a href="https://www.linkedin.com/in/dutoitjohann/" className="about-person-action">
                    <span className="fab fa-linkedin"></span>
                  </a>
                  <a href="mailto:johann@huskycodes.com" className="about-person-action">
                    <span className="fas fa-envelope"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}

ReactDOM.render(<AppView />, document.getElementById("root"));
